import { logEvent } from "@firebase/analytics";
import { useScrollTrigger } from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { analytics } from "../../firebase/firebaseConfig";
import { ImiPathsUrls } from "../../utils/ImiUtilData";
import FriendShareDialog from "../Game/FriendShareDialog";
import LoginDialog from "../Game/LoginDialog";
import SubscribeDialog from "../Game/SubscribeDialog";
import ImiBottomNav from "./ImiBottomNav";
import ImiTopAppBar from "./ImiTopAppBar";

const ImiAppBars = (props) => {
  //#region HOOKS & APIS

  const navigate = useNavigate();
  const [isNavBarsHidden, setNavBarStatus] = useState(false);
  const location = useLocation();
  const trigger = useScrollTrigger();

  const eventListener = (locationParam, event) => {
    if (typeof event.data === "string") {
      //CHECK FOR JSON OBJECT
      try {
        let commandObj = JSON.parse(event.data);

        if (commandObj.command === "HIDE_NAV") {
          if (!isNavBarsHidden) {
            setNavBarStatus(true);
          }
        } else if (commandObj.command === "EXIT") {
          navigate(locationParam.state?.from?.pathname || ImiPathsUrls.home, {
            replace: true,
          });
        } else if (commandObj.command === "SUBSCRIBE") {
          if (!openSubscribeDialog) {
            setOpenSubscribeDialog(true);
          }
        } else if (commandObj.command === "LOGIN") {
          if (!openLogInDialog) {
            setOpenLogInDialog(true);
          }
        } else if (commandObj.command === "SHARE") {
          logEvent(analytics, 'SHARE_GAME_CLICK',{p:'PWA'});
          setOpenShareDialog({
            state: true,
            data: commandObj.data,
            type: commandObj.type,
          });
        }
      } catch (e) {
        console.log("JSON Parse Error" + e);
      }
    }
  };

  const eventListenerFnRef = useRef(eventListener);

  useEffect(() => {
    window.removeEventListener("message", eventListenerFnRef.current);
    eventListenerFnRef.current = (event) => eventListener(location, event);
    window.addEventListener("message", eventListenerFnRef.current, false);
  }, [location]);

  useEffect(() => {
    if (location.pathname === ImiPathsUrls.game) {
      setNavBarStatus(true);
    } else {
      setNavBarStatus(false);
    }
  }, [location]);

  //#endregion

  //#region LOGIN DIALOG
  const [openLogInDialog, setOpenLogInDialog] = useState(false);

  const onLogInDialogActionBtnClick = (logInBool = false) => {
    if (logInBool) {
      document.getElementById("gameIframe").src += "&ingamelogin=true";
    } else {
      document.getElementById("gameIframe").src += "";
    }

    setOpenLogInDialog(false);
  };

  let logInDialog = <LoginDialog open={openLogInDialog} onClose={onLogInDialogActionBtnClick} />;

  //#endregion

  //#region SUBSCRIBE DIALOG
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false);

  const onSubscribeDialogActionBtnClick = () => {
    document.getElementById("gameIframe").src += "";
    setOpenSubscribeDialog(false);
  };

  let subscribeDialog;

  if (openSubscribeDialog) {
    subscribeDialog = <SubscribeDialog open={true} onClose={onSubscribeDialogActionBtnClick} />;
  } else {
    subscribeDialog = null;
  }

  //#endregion

  //#region SHARE DIALOG
  const [openShareDialog, setOpenShareDialog] = useState({
    state: false,
    data: null,
    type: null,
  });

  const [type, setType] = useState("");

  const onCustomShareDialogActionBtnClick = () => {
    setOpenShareDialog({
      state: false,
      data: null,
    });
  };
  let customShareDialog;

  if (openShareDialog.state) {
    if (openShareDialog.type === "FRIEND") {
      customShareDialog = <FriendShareDialog data={openShareDialog.data} onClose={onCustomShareDialogActionBtnClick} />;
    }
  }
  //#endregion

  const isTopHide = isNavBarsHidden ? true : trigger;

  return (
    <Fragment>
      <ImiTopAppBar trigger={!isTopHide} />
      {props.children}
      <ImiBottomNav trigger={!isNavBarsHidden} />

      {logInDialog}

      {subscribeDialog}
      {openShareDialog.state && customShareDialog}
    </Fragment>
  );
};

export default ImiAppBars;
