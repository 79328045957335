import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const gameApi = createApi({
  reducerPath: "gameApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getGameDetails: builder.mutation({
      query: ({uuid}) => ({
        url: `/lgo/game/${uuid}`,
        method: "GET",
        headers: {

        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
 useGetGameDetailsMutation
} = gameApi;
export default gameApi;
