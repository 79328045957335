import { ArrowBack } from "@mui/icons-material";
import {
  AppBar,
  CssBaseline,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SubScreens } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";

function HideOnScroll(props) {
  const { children, trigger } = props;
  return (
    <Slide appear={false} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

const ImiTopAppBar = (props) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  const styleConfig = {
    parentDiv: {
      height: getElementWidth(90),
    },
    appBar: {
      height: getElementWidth(90),
      width: getElementWidth(720),
      justifyContent: "center",
      left: "auto",
      right: "auto",
    },
    toolbar: {
      height: getElementWidth(90),
    },
    logo: {
      width: "auto",
      height: getElementWidth(90),
    },
    title: {
      flexGrow: 1,
      marginLeft: getElementWidth(15),
      fontWeight: "bold",
      fontSize: getElementWidth(35),
    },
  };

  //#endregion

  //const history = useHistory();
  const navigate = useNavigate();
  //const isBack = useSelector(state=>state.home.canGoBack);
  const activeSubScreen = useSelector((state) => state.home.activeSubScreen);

  //#region SUB SCREEN
  const backHandle = () => {
    //history.goBack();
    navigate(-1);
  };

  let appBarContent;

  if (activeSubScreen === SubScreens.MY_SUBS) {
    appBarContent = (
      <Fragment>
        <IconButton
          aria-label="back"
          onClick={backHandle}
          style={{ paddingLeft: 0, paddingRight: 10 }}
        >
          <ArrowBack />
        </IconButton>
        <Typography color="text.primary" className={classes.title}>
          My Subscriptions
        </Typography>
      </Fragment>
    );
  } else {
    appBarContent = (
      <Fragment>
        <img
          style={{ ...styleConfig.logo }}
          src="/appbar_logo.png"
          alt="appbar_logo"
        ></img>
        <Typography color="text.primary" style={styleConfig.title}>
          IMI Games
        </Typography>
      </Fragment>
    );
  }
  //#endregion

  return (
    <div
      style={{
        ...styleConfig.parentDiv,
      }}
    >
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          style={{
            ...styleConfig.appBar,
          }}
          elevation={0}
        >
          <Toolbar
            style={{
              ...styleConfig.toolbar,
            }}
          >
            {appBarContent}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </div>
  );
};

export default ImiTopAppBar;
