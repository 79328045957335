import { configureStore, createSlice } from "@reduxjs/toolkit";
import catalogApi from "../services/catalog";
import gameApi from "../services/game";
import subscriptionApi from "../services/subscription";
import subsListApi from "../services/subsList";
import userApi from "../services/user";
import { IMIThemeMode } from "../utils/ImiUtilData";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    canGoBack: false,
    userToken: null,
    userData: null,
    themeMode: IMIThemeMode.DARK,
    activeUrlData: null,
    activeGameUUID: null,
    activeSubScreen: null,
    isInFb: false,
    isIOSDevice: false,
    isInFirefox: false,
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUserData: (state, action) => {
      if (action.payload) {
        state.userData = {
          name: action.payload.name,
          image: action.payload.image,
        };
      } else {
        state.userData = null;
      }
    },
    activateBack: (state, action) => {
      state.canGoBack = action.payload;
    },
    changeTheme: (state, action) => {
      state.themeMode = action.payload;
    },
    setActiveUrlData: (state, action) => {
      state.activeUrlData = {
        isGame: action.payload.isGame,
        url: action.payload.url,
      };
    },
    setActiveGameUUID: (state, action) => {
      state.activeGameUUID = action.payload;
    },
    //NOT SUBSCRIPTION SCREEN
    setActiveSubScreen: (state, action) => {
      state.activeSubScreen = action.payload;
    },
    checkIsInFb: (state, action) => {
      state.isInFb = action.payload;
    },
    setIsIOSDevice: (state, action) => {
      state.isIOSDevice = action.payload;
    },
    setIsInFirefox: (state, action) => {
      state.isInFirefox = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [catalogApi.reducerPath]: catalogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [subsListApi.reducerPath]: subsListApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(catalogApi.middleware)
      .concat(userApi.middleware)
      .concat(subsListApi.middleware)
      .concat(subscriptionApi.middleware)
      .concat(gameApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
