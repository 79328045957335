import { initializeApp } from "firebase/app";
import { getAnalytics} from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyAHNYWp0zHZIMuSsB57GQF6gKu0UiSkOy8",
  authDomain: "imigames-204412.firebaseapp.com",
  databaseURL: "https://imigames-204412.firebaseio.com",
  projectId: "imigames-204412",
  storageBucket: "imigames-204412.appspot.com",
  messagingSenderId: "356024293196",
  appId: "1:356024293196:web:6cb4db827a970ea816e1e9",
  measurementId: "G-X0CF405ZBM",
};
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
