import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import { googleWebClientID } from "./utils/ImiUtilData";

ReactDOM.render(
  <GoogleOAuthProvider clientId={googleWebClientID}>
    <BrowserRouter>
      <Provider store={store}>
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <div
            id="innerDiv"
            style={{
              position: "relative",
              maxWidth: "100vw",
              height: "100vh",
              width: "576px",
            }}
          >
            <App />
          </div>
        </div>
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
