import { Close, PhoneAndroidRounded } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import {
  useFetchTelcoListQuery,
  useSendMSISDNMutation,
  useSendOTPMutation,
} from "../../services/subscription";
import FeedbackDialog from "../../utils/FeedbackDialog";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementWidth } from "../../utils/ScreenManager";

const SubscribeDialog = ({ onClose, open }) => {
  //#region STYLE
  const useStyles = makeStyles(() => ({}));

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  const token = useSelector((state) => state.home.userToken);
  const uuid = useSelector((state) => state.home.activeGameUUID);

  //console.log(token);

  const telcoList = useRef(null);

  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    package: null,
    error: false,
    disable: false,
  });

  const [otp, setOTP] = useState({
    otp: "",
    error: false,
    disable: true,
  });

  //GET TELCO LIST API
  const {
    data: telcoAPIData,
    error: telcoAPIError,
    isLoading: isLoadingTelcoAPI,
  } = useFetchTelcoListQuery({
    userToken: token,
    gameUUID: uuid,
  });

  //SEND MSISDN API
  const [
    sendMSISDN,
    {
      data: sendMSISDNAPIData,
      error: sendMSISDNAPIError,
      isLoading: isLoadingSendMSISDNAPI,
    },
  ] = useSendMSISDNMutation();

  //SEND OTP API
  const [
    senOtp,
    {
      data: sendOtpAPIData,
      error: sendOtpAPIError,
      isLoading: isLoadingSendOtpAPI,
    },
  ] = useSendOTPMutation();
  //#endregion

  //#region INPUT FIELDS

  const onPhoneNumChanged = (event) => {
    //console.log(telcoList.current[0].regex);
    let num = event.target.value;

    //console.log(num);

    if (num.length === 0) {
      setPhoneNumber({
        number: "",
        package: null,
        error: false,
        disable: true,
      });
      return;
    }

    if (num.length > 10) {
      return;
    }

    for (let i = 0; i < telcoList.current.length; i++) {
      if (num.match(telcoList.current[i].regex)) {
        setPhoneNumber({
          number: num,
          package: telcoList.current[i].package_name,
          error: false,
          disable: false,
        });
        return;
      }
    }

    setPhoneNumber({
      number: num,
      package: null,
      error: true,
      disable: true,
    });

    //onSearch(event.target.value);
  };

  const phoneNumCloseButtonHandler = () => {
    setPhoneNumber({
      number: "",
      package: null,
      error: false,
      disable: true,
    });

    //onClose();
  };

  const onOTPChanged = (event) => {
    //console.log(telcoList.current[0].regex);
    let otpValue = event.target.value;

    if (otpValue.length === 0) {
      setOTP({
        otp: otpValue,
        error: false,
        disable: true,
      });
      return;
    }

    if (otpValue.length > 6) {
      return;
    }

    let regex = new RegExp("^[0-9]{6}$");

    if (otpValue.match(regex)) {
      setOTP({
        otp: otpValue,
        error: false,
        disable: false,
      });
      return;
    }

    setOTP({
      otp: otpValue,
      error: true,
      disable: true,
    });

    //onSearch(event.target.value);
  };
  //#endregion

  //#region SUBSCRIPTION APIS STATES

  const [subscriptionStateIndex, setSubscriptionStateIndex] = useState(0);

  let subscriptionStateContent = null;

  //TELCO API
  if (subscriptionStateIndex === 0) {
    if (isLoadingTelcoAPI) {
      subscriptionStateContent = <LoadingDialog />;
    } else if (telcoAPIError) {
      //console.log(telcoAPIError);

      subscriptionStateContent = (
        <FeedbackDialog
          id="Telco Error Dialog"
          open={true}
          onClose={() => {
            onClose();
          }}
          data={{
            title: "Oops!",
            msg: "Error",
          }}
        />
      );
    } else if (telcoAPIData) {
      telcoList.current = telcoAPIData.map((entry) => {
        return {
          providerName: entry.provider_name,
          package_name: entry.package_name,
          currency: entry.currency,
          amount: entry.amount,
          regex: new RegExp(entry.regex_pattern),
        };
      });

      setSubscriptionStateIndex(1);
    }
    //PHONE NUM DIALOG
  } else if (subscriptionStateIndex === 1) {
    let helperText = "";
    let currency = telcoList.current[0].currency;
    let amount = telcoList.current[0].amount;
    let providerList = telcoList.current.map((entry) => entry.providerName);

    if (providerList.length === 1) {
      helperText = `Only ${providerList[0]} subscribers can submit the score`;
    } else {
      let exList = providerList.slice(0, providerList.length - 1);
      let exString = exList.join(",");

      helperText = `Only ${exString} and ${providerList[
        providerList.length - 1
      ]} subscribers can submit the score`;
    }

    subscriptionStateContent = (
      <Dialog fullWidth={true} open={open}>
        <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
          <Typography
            style={{
              fontSize: getElementWidth(35),
              textAlign: "left",
              fontWeight: "100",
            }}
          >
            Subscribe to Win Rewards!
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ padding: getElementWidth(10) }}>
          <TextField
            style={{
              width: "100%",
              borderRadius: "0",
              height: getElementWidth(100),
              fontSize: getElementWidth(30),
              marginBottom: "40px",
              padding: "0px",
            }}
            type="number"
            //classes={classesNew}
            error={phoneNumber.error}
            id="outlined-error-helper-text"
            //label="Error"
            placeholder="07XXXXXXXX"
            variant="outlined"
            value={phoneNumber.number}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidRounded />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="close"
                    onClick={phoneNumCloseButtonHandler}
                    style={{ padding: "0px" }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={onPhoneNumChanged}
            helperText={
              <Typography
                component={"span"}
                style={{
                  fontSize: getElementWidth(18),
                  textAlign: "left",
                  fontWeight: "normal",
                  whiteSpace: "pre-line",
                }}
              >
                {helperText}
              </Typography>
            }
          />
          <div style={{ height: getElementWidth(50) }} />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                sendMSISDN({
                  userToken: token,
                  type: phoneNumber.package,
                  gameUUID: uuid,
                  phoneNum: phoneNumber.number,
                });
                setSubscriptionStateIndex(2);
              }}
              style={{
                fontSize: getElementWidth(17),
                textTransform: "none",
                alignSelf: "center",
                marginBottom: "10px",
              }}
              disableElevation
              disabled={phoneNumber.disable}
            >
              Submit
            </Button>
          </div>
          <Typography
            style={{
              fontSize: getElementWidth(17),
              textAlign: "center",
              fontWeight: "normal",
              lineHeight: "normal",
              whiteSpace: "pre-line",
            }}
          >{`You will be charged ${currency} ${amount} + tax daily.\n Unsubscribe at any time`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            <Typography
              style={{
                textTransform: "none",
                fontSize: getElementWidth(30),
                textAlign: "left",
              }}
            >
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  //SEND MSISDN API
  else if (subscriptionStateIndex === 2) {
    if (isLoadingSendMSISDNAPI) {
      subscriptionStateContent = <LoadingDialog />;
    } else if (sendMSISDNAPIError) {
      //console.log(sendMSISDNAPIError);

      subscriptionStateContent = (
        <FeedbackDialog
          id="MSISDN ERROR"
          open={true}
          onClose={() => {
            onClose();
          }}
          data={{
            title: "Oops!",
            msg: sendMSISDNAPIError.data.errorMessage,
          }}
        />
      );
    } else if (sendMSISDNAPIData) {
      setSubscriptionStateIndex(3);
      //console.log("Here", sendMSISDNAPIData);
    }
  } else if (subscriptionStateIndex === 3) {
    subscriptionStateContent = (
      <Dialog fullWidth={false} open={open}>
        <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
          <Typography
            style={{
              fontSize: getElementWidth(35),
              textAlign: "left",
              fontWeight: "100",
            }}
          >
            Enter OTP
          </Typography>
        </DialogTitle>
        <DialogContent dividers style={{ padding: getElementWidth(10) }}>
          <TextField
            style={{
              width: "100%",
              borderRadius: "0",
              height: getElementWidth(100),
              fontSize: getElementWidth(30),
              marginBottom: "40px",
              padding: "0px",
            }}
            type="number"
            error={otp.error}
            placeholder="123456"
            variant="outlined"
            value={otp.otp}
            onChange={onOTPChanged}
            helperText={
              <Typography
                component={"span"}
                style={{
                  fontSize: getElementWidth(18),
                  textAlign: "left",
                  fontWeight: "normal",
                  whiteSpace: "pre-line",
                }}
              >
                {`Please type the verification code sent to ${phoneNumber.number}`}
              </Typography>
            }
          />
          {/* <div style={{ height: getElementWidth(50) }} /> */}

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                senOtp({
                  userToken: token,
                  type: phoneNumber.package,
                  gameUUID: uuid,
                  phoneNum: phoneNumber.number,
                  otp: otp.otp,
                });
                setSubscriptionStateIndex(4);
              }}
              style={{
                fontSize: getElementWidth(17),
                textTransform: "none",
                alignSelf: "center",
                marginBottom: "10px",
              }}
              disableElevation
              disabled={otp.disable}
            >
              Submit
            </Button>
          </div>
          <Typography
            style={{
              fontSize: getElementWidth(17),
              textAlign: "center",
              fontWeight: "normal",
              lineHeight: "normal",
              whiteSpace: "pre-line",
            }}
          >
            {}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} color="primary">
            <Typography
              style={{
                textTransform: "none",
                fontSize: getElementWidth(30),
                textAlign: "left",
              }}
            >
              Cancel
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  //SEND OTP API
  else if (subscriptionStateIndex === 4) {
    if (isLoadingSendOtpAPI) {
      subscriptionStateContent = <LoadingDialog />;
    } else if (sendOtpAPIError) {
      //console.log(sendOtpAPIError);

      subscriptionStateContent = (
        <FeedbackDialog
          id="SEND OTP ERROR"
          open={true}
          onClose={() => {
            onClose();
          }}
          data={{
            title: "Oops!",
            msg: sendOtpAPIError.data.errorMessage,
          }}
        />
      );
    } else if (sendOtpAPIData) {
      setSubscriptionStateIndex(0);
      onClose();
      //console.log("Here", sendMSISDNAPIData);
    }
  }

  //#endregion

  return <Fragment>{subscriptionStateContent}</Fragment>;
};

export default SubscribeDialog;
