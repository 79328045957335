import { Button, Dialog, Typography, Box, IconButton } from "@mui/material";
import { toBlob } from "html-to-image";
import React, { Fragment, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import CancelIcon from "@mui/icons-material/Cancel";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { useEffect } from "react";
import { logEvent } from "@firebase/analytics";
import { analytics } from "../../firebase/firebaseConfig";

export default function FriendShareDialog({ data, onClose }) {
  //#region HOOKS
  const urlData = useSelector((state) => state.home.activeUrlData);
  const userData = useSelector((state) => state.home.userData);
  const isIos = useSelector((state) => state.home.isIOSDevice);
  const isInFirefoxBrowser = useSelector((state) => state.home.isInFirefox);

  const imageRef = useRef(null);
  const dataUrlRef = useRef("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ state: false, errorMsg: null });
  const [imageOnLoad, setImageOnLoad] = useState(false);

  const bgImageUrl = urlData.url.split("index.html")[0] + "post_assets/background.png";

  useEffect(() => {
    if (isInFirefoxBrowser) {
      setError({
        state: true,
        errorMsg: "Browser does not support this feature",
      });
    } else {
      base64Image(bgImageUrl, function (dataUrl) {
        //console.log("RESULT:", dataUrl);
        if (dataUrl) {
          dataUrlRef.current = dataUrl;
          setImageOnLoad(true);
        } else {
          setError({ state: true, errorMsg: "Something Went Wrong...!" });
        }
      });
    }
  }, []);
  //#endregion

  //#region UTIL FNS
  const base64Image = (src, callback) => {
    var image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      let base64String = canvas.toDataURL("image/png");
      callback(base64String);
    };
    image.src = src;
  };

  const handleShare = async () => {
    logEvent(analytics, 'SHARE_CHALLENGE_CLICK', { p: "PWA" });
    let shareData;
    if (isIos) {
      shareData = {
        text: `${userData?.name ?? ""} scored a staggering ${data?.score ?? 0} on ${
          data?.game_name ?? ""
        } and challenges you to beat it.\n Are you up to the Challenge? \n ${data?.dynamic_link ?? "https://app.imigames.io/home"}`,
      };
    } else {
      const newFile = await toBlob(imageRef.current);

      shareData = {
        files: [
          new File([newFile], "share.png", {
            type: newFile.type,
          }),
        ],
        title: "share",
        text: `Are you up to the Challenge? \n ${data?.dynamic_link ?? "https://app.imigames.io/home"}`,
      };
    }

    try {
      if (!navigator.canShare(shareData)) {
        setError({ state: true, errorMsg: "Something Went Wrong...!" });
        return;
      }
      setLoading(true);
      await navigator
        .share(shareData)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError({ state: true, errorMsg: "Something Went Wrong...!" });
        });
    } catch (err) {
      setLoading(false);
      setError({ state: true, errorMsg: "Something Went Wrong...!" });
    }
  };
  //#endregion

  //#region SHARE DIALOG CONTENT

  let topCloseBtnContent = (
    <Box
      sx={{
        paddingRight: "10px",
        height: getElementHeight(150),
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
      }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={() => {
          onClose();
        }}
        aria-label="close"
      >
        <CancelIcon sx={{ fontSize: "2.5rem" }} />
      </IconButton>
    </Box>
  );

  let titleContent = (
    <Box
      sx={{
        height: getElementHeight(200),
        display: "flex",
        justifyContent: "center",
        paddingTop: "30px",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "700", fontFamily: "Poppins" }}>
        beat your score!
      </Typography>
    </Box>
  );

  let shareImageContent = (
    <div
      ref={imageRef}
      style={{
        whiteSpace: "nowrap",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100vw",
        width: getElementWidth(720),
        backgroundImage: `url(${dataUrlRef.current ?? "Share Images/FriendShare.png"})`,
      }}
    >
      <Box
        sx={{
          height: getElementWidth(150),
          //backgroundColor: "red",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "10px",
          paddingBottom: "2px",
        }}
      >
        <Typography>
          <span
            style={{
              fontSize: getElementWidth(30),
              fontWeight: "700",
              fontFamily: "Poppins",
            }}
          >
            {userData?.name ?? ""}
          </span>{" "}
          <span
            style={{
              fontSize: getElementWidth(27),
              fontWeight: "700",
              fontFamily: "Poppins",
            }}
          >
            recorded a staggering
          </span>
        </Typography>
        <Typography
          style={{
            color: "rgba(249,237,50,255)",
            fontSize: getElementWidth(37),
            fontWeight: "700",
            letterSpacing: "0.01em",
            fontFamily: "Poppins",
          }}
        >
          Score of {data?.score ?? 0} on
        </Typography>
      </Box>
      <Box
        sx={{
          height: getElementWidth(470),
          //backgroundColor: "green",
        }}
      ></Box>
      <Box
        sx={{
          height: getElementWidth(100),
          //backgroundColor: "red",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            color: "rgba(251,200,46,255)",
            fontSize: getElementWidth(48),
            fontWeight: "700",
            letterSpacing: "0.01em",
            fontFamily: "Poppins",
          }}
        >
          Can you beat the score?
        </Typography>
      </Box>
    </div>
  );

  let bottomChallengeBtnContent = (
    <Box display="flex" justifyContent={"center"} alignItems={"center"}>
      <Button
        onClick={() => {
          handleShare();
        }}
        style={{
          backgroundColor: "rgba(206,60,55,255)",
          color: "white",
          fontWeight: "700",
          padding: "10px",
          margin: "10px 0px",
          fontFamily: "Poppins",
        }}
      >
        Challenge
      </Button>
    </Box>
  );

  let shareDialogContent = (
    <>
      {loading && <LoadingDialog />}
      {imageOnLoad ? (
        <Dialog
          PaperProps={{
            style: {
              background: "rgba(0,0,0,0.4)",
            },
          }}
          open={true}
          fullScreen
        >
          {topCloseBtnContent}
          {titleContent}
          {shareImageContent}
          {bottomChallengeBtnContent}
          <Box sx={{ height: "auto", backgroundColor: "green" }}></Box>
        </Dialog>
      ) : (
        <LoadingDialog />
      )}
    </>
  );
  //#endregion

  return (
    <Fragment>
      {error.state ? (
        <FeedbackDialog
          open={true}
          onClose={() => {
            onClose();
          }}
          data={{
            title: "Oops!",
            msg: error.errorMsg,
          }}
        ></FeedbackDialog>
      ) : (
        shareDialogContent
      )}
    </Fragment>
  );
}
