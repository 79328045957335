import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { getElementWidth } from "./ScreenManager";

export default function PageUnderConstruction() {
  return (
    <Dialog
      open={true}
      fullScreen={true}
      PaperProps={{
        style: {
          backgroundImage: `url("/maintenance.png")`,
          boxShadow: "none",
          backgroundRepeat: "no-repeat",
          whiteSpace: "nowrap",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        },
      }}
    >
      <DialogContent style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", backgroundColor: "rgba(255, 255, 255,0.5)" }}>
        <Box
          style={{
            backgroundImage: `url(/logo.png)`,
            backgroundRepeats: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            borderRadius: "50%",
            zIndex: "1",
            width: getElementWidth(180),
            height: getElementWidth(180),
            boxSizing: "border-box",
          }}
        />

        <Box style={{ height: "3vh" }} />
        <Typography style={{ textAlign: "center", fontWeight: "700", fontSize: getElementWidth(42), color: "black", fontFamily: "Poppins" }}>
          We are under
          <br />
          <span style={{ color: "rgba(206, 60, 54,255)", fontWeight: "700", fontSize: getElementWidth(42) }}>maintenance.</span>
          <br />
          We will be back soon.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
